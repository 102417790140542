import React, { useMemo } from "react";
import * as classes from "./CustomAlert.module.css";
import {
  MdErrorOutline,
  MdInfoOutline,
  MdDoneOutline,
  MdClose,
  MdOutlineWarning
} from "react-icons/md";
function CustomAlert({
  children,
  severity,
  variant,
  elevation,
  onClose,
  style
}) {
  const tempStyle = { ...style };
  const pickIcon = useMemo(() => {
    switch (severity) {
      case "success":
        return <MdDoneOutline />;
      case "error":
        return <MdErrorOutline />;
      case "warning":
        return <MdOutlineWarning />;
      case "info":
        return <MdInfoOutline />;
    }
  }, [severity]);
  return (
    <div
      severity={severity}
      elevation={elevation}
      variant={variant}
      className={
        classes.wrapper + " " + classes.filledError + " " + classes.elevation6
      }
      style={{ ...tempStyle }}
    >
      <div className={classes.alertIcon}>{pickIcon}</div>
      <div className={classes.alertMessage}>{children}</div>
      {onClose ? (
        <div className={classes.action} onClick={onClose}>
          <button className={classes.sizeSmall}>
            <span className={classes.label}>
              <MdClose />
            </span>
          </button>
        </div>
      ) : null}
    </div>
  );
}

export default CustomAlert;
