import React, { useMemo } from "react";
import * as classes from "./SnackBar.module.css";
function SnackBar({ children, open, autoHideDuration, onClose }) {
  useMemo(() => {
    if (autoHideDuration) {
      return setTimeout(onClose, autoHideDuration);
    }
  }, autoHideDuration);
  return (
    <div open={open} className={classes.wrapper} onClick={onClose}>
      {children}
    </div>
  );
}

export default SnackBar;
