/* Copyright 2020 Avetti.com Corporation - All Rights Reserved

This source file is subject to the Avetti Commerce Front End License (ACFEL 1.20)
that is accessible at https://www.avetticommerce.com/license */
import React, { useState, useEffect, useRef } from "react";
import { useSelector, shallowEqual } from "react-redux";
import CircularProgress from "../AC-UI-Elements/CircularProgress/CircularProgress";
import SnackBar from "../AC-UI-Elements/SnackBar/SnackBar";
import CustomAlert from "../AC-UI-Elements/CustomAlert/CustomAlert";
import Dialog from "../AC-UI-Elements/Dialog/Dialog";
import DialogContent from "../AC-UI-Elements/DialogContent/DialogContent";
import { MdClose } from "react-icons/md";

const initialFormData = {};

const formFields = [
  {
    id: "NameofEventthisdonationisfor",
    name: "Name of Event this donation is for",
    type: "text",
    pattern: /[^\n]+/,
    maxLength: "250",
    isRequired: true
  },
  {
    id: "FullName",
    name: "Full Name",
    type: "text",
    pattern: /[^\n]+/,
    maxLength: "250",
    isRequired: true
  },
  {
    id: "Email",
    name: "Email",
    displayName: "Email",
    type: "email",
    pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
    maxLength: "250",
    isRequired: true
  },
  {
    id: "CompanyName",
    name: "Company Name",
    type: "text",
    pattern: /[^\n]+/,
    maxLength: "250",
    isRequired: true
  },
  {
    id: "CompanyEmail",
    name: "Company Email",
    displayName: "Company Email",
    type: "email",
    pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
    maxLength: "250",
    isRequired: true
  },
  {
    id: "CompanyAddress",
    name: "Company Address",
    type: "text",
    pattern: /[^\n]+/,
    maxLength: "250",
    isRequired: true
  },
  {
    id: "CompanyWebsiteUrl",
    name: "Company Website Url",
    type: "text",
    pattern: /[^\n]+/,
    maxLength: "250",
    isRequired: true
  },
  {
    id: "MonetaryValueofyourDonation",
    name: "Monetary Value of your Donation",
    type: "text",
    pattern: /^[a-zA-Z0-9_.+-, ]*$/,
    maxLength: "250",
    isRequired: true
  },
  {
    id: "UrltoapictureofthisDonation",
    name: "Url to a picture of this Donation",
    displayName: "Url to a picture of this Donation (If applicable)",
    type: "text",
    pattern: /[^\n]+/,
    maxLength: "250",
    isRequired: false
  },
  {
    id: "URLtotheLogoofyourCompany",
    name: "URL to the Logo of your Company",
    type: "text",
    pattern: /[^\n]+/,
    maxLength: "250",
    isRequired: false
  },
  {
    id: "ShortDescriptionofthisDonation",
    name: "Short Description of this Donation",
    type: "text",
    pattern: /[^\n]+/,
    maxLength: "250",
    isRequired: true
  },
  {
    id: "LongDescriptionofthisDonation",
    name: "Long Description of this Donation",
    type: "text",
    pattern: /[^\n]+/,
    maxLength: "500",
    isRequired: true
  }
];

function Alert(props) {
  return <CustomAlert elevation={6} variant="filled" {...props} />;
}

function Donate() {
  const formRef = useRef();
  const [formData, updateFormData] = React.useState(initialFormData);
  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [openError, setOpenError] = React.useState(false);
  const [snackstate, setSnackState] = React.useState({
    vertical: "bottom",
    horizontal: "left"
  });
  const { vertical, horizontal } = snackstate;
  const openChat = () => {
    if (typeof window !== undefined) {
      window.LC_API.open_chat_window();
    }
  };

  const handleInputChange = e => {
    updateFormData({
      ...formData,
      // Trimming any whitespace
      [e.target.id]: e.target.value.replace(/\s+/g, " ")
    });
  };

  //   const selectedQuote = useSelector(
  //     state => state.quoteReducer.selectedQuote,
  //     shallowEqual
  // );

  const isValidated = () => {
    let inputErrors = {};
    let isValid = true;

    formFields &&
      formFields.forEach(data => {
        let inputData = formData[data.id];
        let inputPattern = data.pattern;
        if ((inputData === "" || inputData === " ") && data.isRequired) {
          inputErrors[data.id] = "Required";
          isValid = false;
        } else if (
          inputData !== "" &&
          inputPattern &&
          !inputPattern.test(inputData)
        ) {
          switch (data.id) {
            case "NameofEventthisdonationisfor":
              inputErrors[data.id] =
                "Name of Event this donation is for Required";
              return (isValid = false);
            case "FullName":
              inputErrors[data.id] = "Full Name Required";
              return (isValid = false);
            case "Email":
              inputErrors[data.id] = "Email Required";
              return (isValid = false);
            case "CompanyName":
              inputErrors[data.id] = "Company Name Required";
              return (isValid = false);
            case "CompanyEmail":
              inputErrors[data.id] = "Company Email Required";
              return (isValid = false);
            case "CompanyAddress":
              inputErrors[data.id] = "Company Address Required";
              return (isValid = false);
            case "CompanyWebsiteUrl":
              inputErrors[data.id] = "Company Website Url Required";
              return (isValid = false);
            case "MonetaryValueofyourDonation":
              inputErrors[data.id] = "Monetary Value Required";
              return (isValid = false);
            case "UrltoapictureofthisDonation":
              inputErrors[data.id] =
                "If you leave the above empty we will contact you to get the image.";
              return (isValid = false);
            case "URLtotheLogoofyourCompany":
              inputErrors[data.id] =
                "If you leave the above empty we will contact you to get the image.";
              return (isValid = false);
            case "ShortDescriptionofthisDonation":
              inputErrors[data.id] = "Short Description Required";
              return (isValid = false);
            case "LongDescriptionofthisDonation":
              inputErrors[data.id] = "Long Description Required";
              return (isValid = false);
            default:
              inputErrors[data.id] = "all errors";
              return (isValid = false);
          }
        } else {
          inputErrors[data.id] = "";
        }
      });
    setErrors({ ...errors, ...inputErrors });

    return isValid;
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (isValidated()) {
      const data = new FormData(formRef.current);
      setIsLoading(true);
      fetch(
        "https://script.google.com/macros/s/AKfycbzReNASJDW0s06MOjrOjSMyALvotXg2wm1VzcfLKRqUfF-hp1xQLDAEkoJOrw8R9jzvyA/exec",
        {
          method: "POST",
          mode: "no-cors",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: data
        }
      )
        .then(response => {
          setIsLoading(false);
          setOpen(true);
          formRef.current.reset();
          return response;
        })
        .catch(err => {
          console.log(err);
          setOpenError(true);
        });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };

  const handleCloseError = () => {
    setOpenError(false);
  };

  useEffect(() => {
    setTimeout(function () {
      const forms = document.getElementById("RTDonate");
      console.log("formsss", forms);
      [...forms.elements].forEach(({ id, value }) => {
        initialFormData[id] = value;
      });
    }, 2000);
  }, []);

  const [loadLiveChat, setLoadLiveChat] = useState(false);

  useEffect(() => {
    setTimeout(() => setLoadLiveChat(true), 5000);
  }, []);

  const [state, setState] = React.useState({
    AcceptsEmail: true
  });

  const handleChangeYes = event => {
    setState({ ...state, AcceptsEmail: !state.AcceptsEmail });
    console.log("Accept Emails", state);
  };

  const langState = useSelector(state => state.mainReducer.lang, shallowEqual);

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  //const [openChatBots, setopenChatBots] = useState(false);

  const loginNameState = useSelector(
    state => state.loginReducer.loginName,
    shallowEqual
  );

  return (
    <div id="footer">
      <div>
        <div className="fo">
          <div className="RTFormdiv" id="contactForm">
            <h1>Donate an Item to the Rotary Club of Barrie</h1>
            <p>
              Thank you for your donation. Depending on the event this will be
              used as a Silent Auction Item or a Prize. Please fill in the
              information below
            </p>
            <>
              <Dialog
                open={open}
                onClose={handleClose}
                autoHideDuration={5000}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <div>
                  <MdClose
                    style={{ cursor: "pointer" }}
                    onClick={handleClose}
                  />
                </div>
                <DialogContent>
                  <div id="alert-dialog-description">
                    <div className="SucessRT">
                      <img src="https://ik.imagekit.io/ofb/ofb/thank-you_xUk43V_rZG.svg?w-24,h-24" />
                      <p>Rotary Club of Barrie</p>
                      <span>
                        Email
                        <a href="mailto:hockey.barrie.rotary@gmail.com">
                          hockey.barrie.rotary@gmail.com
                        </a>
                        if you have any questions
                      </span>
                    </div>
                  </div>
                </DialogContent>
              </Dialog>
              <SnackBar
                open={openError}
                autoHideDuration={5000}
                onClose={handleCloseError}
                anchorOrigin={{ vertical, horizontal }}
              >
                <Alert onClose={handleCloseError} severity="error">
                  Failed
                </Alert>
              </SnackBar>
              <form
                noValidate
                onSubmit={handleSubmit}
                method="post"
                ref={formRef}
                id="RTDonate"
                name="RTDonate"
                className="quote-form"
              >
                {formFields &&
                  formFields.map(data => {
                    let fields = "";

                    if (data.id === "HowCanWeHelp") {
                      fields = (
                        <div className="form-group" key={data.id}>
                          <label htmlFor={data.id} className="text-dark">
                            {data.name == "Correo"
                              ? data.displayName
                              : data.name}
                            {data.isRequired && (
                              <span className="required">*</span>
                            )}
                          </label>
                          <select
                            onChange={handleInputChange}
                            value={formData[data.id]}
                            maxLength={data.maxLength}
                            required={data.isRequired}
                            id={data.id}
                            name={data.name}
                          >
                            {data.options &&
                              data.options.map((option, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={option == "Select" ? "" : option}
                                  >
                                    {option}
                                  </option>
                                );
                              })}
                          </select>
                          {errors[data.id] !== "" && (
                            <span className="error-rt">{errors[data.id]}</span>
                          )}
                        </div>
                      );
                    } else if (
                      data.id === "ShortDescriptionofthisDonation" ||
                      data.id === "LongDescriptionofthisDonation"
                    ) {
                      fields = (
                        <div className="form-group" key={data.id}>
                          <label
                            htmlFor={data.id}
                            className="text-dark extramessage"
                          >
                            {data.name == "message"
                              ? data.displayName
                              : data.name}
                            {data.isRequired && (
                              <span className="required">*</span>
                            )}
                          </label>
                          <br />
                          <textarea
                            onChange={handleInputChange}
                            value={formData[data.id]}
                            maxLength={data.maxLength}
                            required={data.isRequired}
                            type={data.type}
                            id={data.id}
                            name={data.name}
                            rows="4"
                            cols="50"
                            pattern={data.pattern}
                            //placeholder={`Enter ${data.name}`}
                          ></textarea>
                          {errors[data.id] !== "" && (
                            <span className="error-rt">{errors[data.id]}</span>
                          )}
                        </div>
                      );
                    } else {
                      fields = (
                        <div className="form-group" key={data.id}>
                          <label htmlFor={data.id} className="text-dark">
                            {data.name == "email" ||
                            data.name == "Url to a picture of this Donation"
                              ? data.displayName
                              : data.name}
                            {data.isRequired && (
                              <span className="required">*</span>
                            )}
                          </label>
                          <input
                            onChange={handleInputChange}
                            value={formData[data.id]}
                            maxLength={data.maxLength}
                            required={data.isRequired}
                            type={data.type}
                            id={data.id}
                            name={data.name}
                            pattern={data.pattern}
                            placeholder={`Enter ${data.name}`}
                          />

                          {data.id === "UrltoapictureofthisDonation" ? (
                            <div className="ifupload">
                              <span>Note :</span> If you leave the above empty
                              we will contact you to get the image.
                            </div>
                          ) : null}
                          {data.id === "URLtotheLogoofyourCompany" ? (
                            <div className="ifupload">
                              <span>Note :</span> If you leave the above empty
                              we will contact you to get the image.
                            </div>
                          ) : null}

                          {errors[data.id] !== "" && (
                            <span className="error-rt">{errors[data.id]}</span>
                          )}
                        </div>
                      );
                    }
                    return fields;
                  })}

                {/* <FormControlLabel
                    control={
                      <GreenCheckbox
                        onChange={handleChangeYes}
                        checked={state.AcceptsEmail ? true : false}
                        value={state.AcceptsEmail ? "Yes" : "No"}
                        name="AcceptsEmail"
                        id="AcceptsEmail"
                      />
                    }
                    label="I permit Rotary to send me an email to thank me in the future for my donation and to ask if I would like to help Rotary and the Community donate again in a future event."
                  /> */}

                <div class="form-group1">
                  <input
                    onChange={handleChangeYes}
                    checked={state.AcceptsEmail ? true : false}
                    value={state.AcceptsEmail ? "Yes" : "No"}
                    name="AcceptsEmail"
                    id="AcceptsEmail"
                    type="checkbox"
                  />
                  <label for="AcceptsEmail">
                    I permit Rotary to send me an email to thank me in the
                    future for my donation and to ask if I would like to help
                    Rotary and the Community donate again in a future event.
                  </label>
                </div>

                <div className="buttonrt">
                  <button
                    className="verified-badge with-tip"
                    disabled={isLoading}
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
                <div className="buttonrt">
                  {isLoading && <CircularProgress color="primary" />}
                </div>
              </form>
            </>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Donate;
