import React from "react";
import * as classes from "./CircularProgress.module.css";
function CircularProgress() {
  return (
    <span className={classes.container + " " + classes.wrapper}>
      <svg viewBox="22 22 44 44" className={classes.svg}>
        <circle className={classes.circle}></circle>
      </svg>
    </span>
  );
}

export default CircularProgress;
