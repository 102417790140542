import React from "react"

import Layout from "../other/layout"
import SEO from "../other/seo"

import Test from "../other/test"
import Donate from "../components/AC-Donate/Donate";

const DonateRT = () => {
  return (
    <Layout>
      <SEO title="Donate" />
      <Donate />
    </Layout>
  )
}

export default DonateRT
